import React from 'react';
import styles from './MainSection.module.css';
import hamburger from './Hamburger.module.css'
import manuData from '../../resources/data/menuLinks.json'
import mainData from '../../resources/data/mainData.json'
import logo from '../../resources/img/logo.png';


export interface IStateMain {
  offset: number;
  hambIsActive: boolean;
  clientWidth: number;
}

export default class Main extends React.Component<any, IStateMain> {
  constructor(props: any) {
    super(props)
    this.state = {
      offset: 0,
      hambIsActive: false,
      clientWidth: document.body.clientWidth
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.parallaxShift);
    window.addEventListener('resize', this.resize);

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.parallaxShift);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({
      clientWidth: document.body.clientWidth
    })
  }

  parallaxShift = () => {
    this.setState({
      offset: window.pageYOffset
    });
  };

  sectionScroll = (e: any, sectionId: string) => {
    const { hambIsActive, clientWidth } = this.state;
    e.preventDefault();
    this.setState({ hambIsActive: !hambIsActive });
    const scrollTarget = document.getElementById(sectionId);
    const topNav = document.getElementById('topNavMenu');
    const topOffset = clientWidth < 1024 ? 0 : topNav ? topNav.offsetHeight : 0;
    const elementPosition = scrollTarget ? scrollTarget.getBoundingClientRect().top : 0;
    const offsetPosition = elementPosition - topOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  render() {
    const { offset, hambIsActive, clientWidth } = this.state;
    let isActive = hambIsActive ? hamburger.isActive : '';
    hambIsActive && clientWidth < 1024 ? document.body.setAttribute('style', 'overflow: hidden;') : document.body.setAttribute('style', 'overflow: auto;')
    return (
      <header>
        <div className={styles.parallaxMain}
          style={{ backgroundPositionY: offset * 0.5 }}>
          <div id="topNavMenu" className={offset < 125 ? styles.topNav : styles.scrolledMenu}>
            <img loading="lazy" src={logo} alt="Logo Maxcreate" />
            {clientWidth < 1024 ? <button className={[hamburger.hamburger, hamburger.hamburgerSqueeze, isActive, styles.hamburger].join(' ')}
              onClick={() => this.setState({ hambIsActive: !hambIsActive })} aria-label="Menu button" type="button">
              <span className={hamburger.hamburgerBox}>
                <span className={hamburger.hamburgerInner}></span>
              </span>
            </button> : ''}

            {hambIsActive || clientWidth >= 1024 ?
              <div className={styles.navbar}>
                <nav>
                  {manuData.value.map(item =>
                    <a key={item.ID} onClick={(e) => this.sectionScroll(e, item.Link.slice(1))} href={item.Link}>{item.Title}</a>)
                  }
                </nav>
              </div>
              : ''}
          </div>
          <div className={styles.parallaxContentDiv}>
            <div className={styles.parallaxContent}>
              <h1>{mainData.value[0].Descrition}</h1>
              <p>{mainData.value[1].Descrition}</p>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
