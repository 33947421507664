import * as React from "react";
import styles from "./ContactSection.module.css"
import gloabalStyles from "../../globalStyles/globalStyles.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import contactData from "../../resources/data/contactData.json"
import { BasicStyleTheme } from '../../constants/Interfaces';
import themeSwitch from '../../components/themeSwitch';
export interface IContactProps extends BasicStyleTheme {
  test: string;
}
export default class Contact extends React.Component<IContactProps, any> {
  constructor(props: any) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }


  contactForm() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/xoqwazlg"
        method="POST"
      >
        <label className={styles.contactLabel} htmlFor="FullName"> <span style={{ color: '#00815F', fontSize: '18px' }}>*</span> Imię i nazwisko:</label>
        <input type="text" name="Name" className={styles.contactInput} id="FullName"/>
        <label className={styles.contactLabel}  htmlFor="Email"> <span style={{ color: '#00815F', fontSize: '18px' }}>*</span> Email:</label>
        <input type="email" name="Email" className={styles.contactInput} id="Email" />
        <label className={styles.contactLabel}  htmlFor="Message"> <span style={{ color: '#00815F', fontSize: '18px' }}>*</span> Treść wiadomości:</label>
        <textarea name="Message" style={{ height: 100 }} className={styles.contactInput} id="Message" />
        {status === "SUCCESS" ? <p>Thanks!</p> : <button className={styles.contactButtonSubmit}>Wyślij</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
        <p>* - pola wymagane</p>
        <p>** - klikając przycisk WYŚLIJ, wyrażasz zgodę na przetwarzanie twoich danych osobowych podanych w powyższym formularzu</p>
      </form>
    );
  }

  render() {
    const { mainStyle } = this.props;
    return (
      <section id="contact" className={[styles.sectionContanct, themeSwitch(mainStyle)].join(' ')}>
        <div className={gloabalStyles.sectionHeader}>
          <h2>Kontakt</h2>
          <p className="expHeadDesc">Zostaw swoją wiadomność</p>
          <hr />
        </div>
        <div className={styles.mainContacntDiv}>
          <div className={styles.contactBoxInfo}>
            <div className={styles.contactInfoItem}>
              <FontAwesomeIcon icon={faMapMarkedAlt} color={'#00815F'} size="2x" />
              <h3>Miejscowość:</h3>
              <p>{contactData.Adress}</p>
            </div>
            <div className={styles.contactInfoItem}>
              <FontAwesomeIcon icon={faMobileAlt} color={'#00815F'} size="2x" />
              <h3>Telefon:</h3>
              <a href={['tel:', contactData.Phone].join('')}>{contactData.Phone}</a>
            </div>
            <div className={styles.contactInfoItem}>
              <FontAwesomeIcon icon={faEnvelope} color={'#00815F'} size="2x" />
              <h3>E-mail:</h3>
              <a href={['mailto:', contactData.Email].join('')}>{contactData.Email}</a>
            </div>
          </div>
          <div className={styles.contactForm}>
            {this.contactForm()}
          </div>
        </div>
      </section >
    )
  }

  submitForm(ev: any) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}