import React from 'react';
import styles from './globalStyles/globalStyles.module.css';
import About from './sections/About/AboutSection'
import Contact from './sections/Contact/ContactSection'
import Portfolio from './sections/Portfolio/PortfolioSection'
// import Experience from './sections/Experience/ExperienceSection'
import Footer from './sections/Footer/FooterSection'
import Main from './sections/Main/MainSection'
import HowIWork from './sections/HowIWork/HowIWork'
import AllWantSite from './sections/AllWantSite/AllWantSite'
import { stylesTheme } from './constants/Interfaces'
export interface IStatePage {
  isLoaderHidden: boolean;
}

export default class Page extends React.Component<any, IStatePage> {
  constructor(props: any) {
    super(props)
    this.state = {
      isLoaderHidden: false
    };
  }

  componentDidMount() {
    window.addEventListener('load', this.parallaxShift);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.parallaxShift);
  }

  parallaxShift = () => {
    this.setState({ isLoaderHidden: true });
  };

  render() {
    return (
      <div id="LandingPage">
        <div className={[styles.loader, this.state.isLoaderHidden ? styles.hide : ''].join(' ')}>
          <div className={styles.loader_inner}></div>
        </div>
        <Main />
        <About mainStyle={stylesTheme.whiteTheme} imageAlt={'Maksym Pidobid Image'} />
        <AllWantSite mainStyle={stylesTheme.silverTheme} test={'Maksym Pidobid Image'} />
        <HowIWork mainStyle={stylesTheme.whiteTheme} test={'Maksym Pidobid Image'} />
        <Portfolio mainStyle={stylesTheme.darkTheme} test={'Maksym Pidobid Image'} />
        <Contact mainStyle={stylesTheme.silverTheme} test={'Maksym Pidobid Image'} />
        <Footer />
      </div>
    );
  }
}