import React from 'react';
import styles from './PortfolioSection.module.css'
import globalStyles from '../../globalStyles/globalStyles.module.css'
import portfolio from '../../resources/data/portfolioData.json'
import { BasicStyleTheme } from '../../constants/Interfaces';
import themeSwitch from '../../components/themeSwitch';
export interface IPortfolioProps extends BasicStyleTheme {
  test: string;
}
export interface IPortfolioState {
  portItems: any;
}

export default class Portfolio extends React.Component<IPortfolioProps, IPortfolioState> {

  constructor(props: any) {
    super(props);
    this.state = {
      portItems: portfolio.values
    }
  }

  changeState(item: any) {
    const { portItems } = this.state;
    portItems[portItems.indexOf(item)].isModalOpen = item.isModalOpen ? false : true;
    portItems[portItems.indexOf(item)].isModalOpen ?
      document.body.setAttribute('style', 'overflow: hidden;') :
      document.body.setAttribute('style', 'overflow: auto;');
    this.setState({ portItems: portItems })
  }


  modal(item: any) {
    let img = require(`../../resources/img/portfolio/${item.fullImg}`)
    return (
      <div className={styles.mainWindowModal}>
        {item.isModalOpen ? <div className={styles.modalWindow} key={item.ID}>
          <div className={styles.modalDescCont}>
            <div className={styles.modalDesc}>
              <h3>{item.name}</h3>
              <p>{item.shortDesc}</p>
            </div>
            <div className={styles.modalExit}>
              <button onClick={() => this.changeState(item)}>×</button>
            </div>
          </div>
          <div className={styles.modalImg}>
            <img loading="lazy" src={img} alt={item.imgFullAllText} />
          </div>
        </div> : undefined}
      </div>
    )
  }

  generateItems = () => {
    const { portItems } = this.state;
    return (
      <div className={styles.portfItems}>
        {portItems.map((item: any) => {
          let bgImg = require('../../resources/img/portfolio/' + item.previewImg)
          return (<div className={styles.portfItem} style={{ backgroundImage: `url(${bgImg})` }} key={item.ID}>
            <div className={styles.hiddenText}>
              <h3>{item.name}</h3>
              <p>{item.shortDesc}</p>
              <button onClick={() => this.changeState(item)}>Zobacz</button>
            </div>
            {item.isModalOpen ? this.modal(item) : undefined}
          </div>)
        })}
      </div>
    )
  }


  render() {
    const { mainStyle } = this.props;
    return (
      <section id="portfolio" className={[styles.portfolioSection, themeSwitch(mainStyle)].join(' ')}>
        <div className={styles.mainDiv}>
          <div className={globalStyles.sectionHeader}>
            <h2>{portfolio.Title}</h2>
            <p>{portfolio.Description}</p>
            <hr />
          </div>
          <div className={styles.portfContent}>
            {this.generateItems()}
          </div>
        </div>
      </section>
    );
  }
}
