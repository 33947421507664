import React from 'react';
import styles from './FooterSection.module.css'
import gloabalStyles from '../../globalStyles/globalStyles.module.css'
import SocialMedia from '../../components/socialMedia/SocialMedia'

export default class Footer extends React.Component<any, any> {
  render() {
    return (
      <footer>
        <div className={[styles.mainFooterClass, gloabalStyles.bg_black].join(' ')}>
          <div className={styles.copyright}>
            <p>© Copyright {new Date().getFullYear()} - Maksym Pidobid</p>
          </div>
          <div className={styles.socialIcons}>
            <SocialMedia className={styles.socMedPosition} />
          </div>
        </div>
      </footer>
    );
  }
}
