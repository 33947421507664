import React from 'react';
import globalStyles from '../../globalStyles/globalStyles.module.css';
import styles from './HowIWork.module.css'
import { BasicStyleTheme } from '../../constants/Interfaces';
import themeSwitch from '../../components/themeSwitch';
import dataImg from '../../resources/img/parts/data.jpg'
import developImg from '../../resources/img/parts/develop.jpg'
import projectImg from '../../resources/img/parts/project.jpg'
export interface IHowIWorkProps extends BasicStyleTheme {
  test: string;
}
export default class HowIWork extends React.Component<IHowIWorkProps, any> {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { mainStyle } = this.props;
    return (
      <section id="HowIWork" className={themeSwitch(mainStyle)}>
        <div className={globalStyles.sectionHeader}>
          <h2>Jak działam?</h2>
          <p>Wygodne warunki współpracy </p>
          <hr />
        </div>
        <div className={styles.mainDivHow}>
          <div className={styles.howRow}>
            <div className={styles.stepsSiteDo}>
              <div className={styles.stepsImage}>
                <img loading="lazy" src={dataImg} alt="Zbieranie informacji" />
              </div>
              <div className={styles.stepsContent}>
                <h3>Ustalenie zakresu pracy</h3>
                <p>Na samym początku ustalamy zakres pracy. Zbieram wszystkie potrzebne informacji na temat celu tej strony, terminy końcowe, od kiedy będziemy mogli zacząć oraz oczywiście chęci klienta. Na podstawie zebranych danych będę w stanie określić ile taka strona będzie kosztować i wtedy będziemy mogli ustalić warunki współpracy. </p>
              </div>
            </div>
            <div className={styles.stepsSiteDo}>
              <div className={styles.stepsImage}>
                <img loading="lazy" src={projectImg} alt="Projektowanie" />
              </div>
              <div className={styles.stepsContent}>
                <h3>Projektowanie</h3>
                <p>Po ustaleniu wszystkich formalności, przygotuję projekt graficzny opierając się o zebrane dane. Będziemy go robić w ścisłej współpracy, doprecyzowując szczegóły i robiąc wszystko według najlepszych praktyk. Ustalony projekt graficzny, będzie w 100% odzwierciedlać to, jak będzie wyglądać strona. Na tym etapie, po stworzeniu projektu graficznego, będę też Państwa prosić o dane, które znajdą się na stronie w poszczególnych miejscach. </p>
              </div>
            </div>
            <div className={styles.stepsSiteDo}>
              <div className={styles.stepsImage}>
                <img loading="lazy" src={developImg} alt="Tworzenie" />
              </div>
              <div className={styles.stepsContent}>
                <h3>Tworzenie</h3>
                <p>Na tym etapie zaczyna się faktyczne powstanie strony. Zaczynam tworzenie według projektu graficznego. Powstanie również środowisko testowe, by później Państwo mogli przetestować stronę przed wdrożeniem. Etapy tworzenia też powstaną w przypadku bardziej złożonej witryny - dogadamy to indywidualnie. Po stworzeniu strony, jak wspomniałem, będą mogły Państwo wejść i poklikać stronę, wypisać ew. błędy. Po rozliczeniu się, uważam projekt za oddany i wszelkiego rodzaju poprawki oraz rozwoje będą kosztować dodatkowo, chyba że również kupią Państwo pakiet utrzymaniowy lub rozwojowy. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
