import React from 'react';
import globalStyles from '../../globalStyles/globalStyles.module.css';
import styles from './AllWantSite.module.css'
import businessImg from '../../resources/img/site/business2.jpg'
import organizationsImg from '../../resources/img/site/organizations2.jpg'
import personImg from '../../resources/img/site/person2.jpg'
import { BasicStyleTheme } from '../../constants/Interfaces';
import themeSwitch from '../../components/themeSwitch';
export interface IAllWantSiteProps extends BasicStyleTheme {
  test: string;
}
export default class AllWantSite extends React.Component<IAllWantSiteProps, any> {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    const { mainStyle } = this.props;
    return (
      <section id="AllWantSite" className={themeSwitch(mainStyle)}>
        <div className={globalStyles.sectionHeader}>
          <h2>Strona</h2>
          <p>Dla kogo? </p>
          <hr />
        </div>
        <div className={styles.mainDivAllWant}>
          <div className={styles.contentAllWant}>
            <div className={styles.someWantSite}>
              <div className={styles.someWantSiteItemImage}> 
                <img loading="lazy" src={personImg} alt="Ikonka"/>
              </div>
              <div className={styles.someWantSiteItemContent}>
                <h2>Osoby prywatne</h2>
                <p>Fotografy, malarze, freelancerzy - wiele osób chce mieć swoją stronę wizytówkę mówiącej o tej osobie i jej działalności trochę więcej.</p>
              </div>
            </div>
            <div className={styles.someWantSite}>
              <div className={styles.someWantSiteItemImage}> 
                <img loading="lazy" src={businessImg} alt="Ikonka"/>
              </div>
              <div className={styles.someWantSiteItemContent}>
                <h2>Biznes</h2>
                <p>Oczywiście biznes, mały jak i ten duży, muszą mieć swoją stronę internetową, niezależnie od branży w której działają. Jest to bardzo potrzebny krok dla reklamy firmy i zwiększaniu rozpoznawalności. Strona może służyć informatorem o danych firmy, jej ofertach oraz doświadczeniu, ale też może to być system wewnątrz-firmowy, z mnóstwem logiki oraz automatyzacją czegokowiek. Z taką stroną czy systemem, biznes może wyjść na nowy poziom</p>
              </div>
            </div>
            <div className={styles.someWantSite}>
              <div className={styles.someWantSiteItemImage}> 
                <img loading="lazy" src={organizationsImg} alt="Ikonka"/>
              </div>
              <div className={styles.someWantSiteItemContent}>
                <h2>Organizacje</h2>
                <p>Często stronę potrzebują również organizacje takie jak fundusze, lub np. urzędy miast. Strona internetowa przyda się każdemu.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
        