import React from 'react';
import globalStyles from '../../globalStyles/globalStyles.module.css';
import styles from './AboutSection.module.css'
import SocialMedia from '../../components/socialMedia/SocialMedia'
import meImg from "../../resources/img/me.jpg"
import AboutMeData from '../../resources/data/aboutMe.json'
import { BasicStyleTheme } from '../../constants/Interfaces';
import themeSwitch from '../../components/themeSwitch';
export interface IAboutProps extends BasicStyleTheme {
  imageAlt: string;
}
export default class About extends React.Component<IAboutProps, any> {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { mainStyle } = this.props;
 
    return (
      <section id="about" className={[styles.mainDivAb, themeSwitch(mainStyle)].join(' ')}>
        <div>
          <div className={globalStyles.sectionHeader}>
            <h2>{AboutMeData.Title}</h2>
            <p>{AboutMeData.Descriptipon}</p>
            <hr />
          </div>
          <div className={styles.aboutContent}>
            <div className={styles.mainImage}>
              <div className={styles.meImage}>
                <img loading="lazy" src={meImg} alt={this.props.imageAlt} width={''} height={''} />
              </div>
              <SocialMedia className={styles.socialIconsAbout} />
            </div>
            <div className={styles.aboutText}>
              <p>Cześć, nazywam się Maksym Pidobid. Jestem twórcą stron internetowych, które zaczynają się pomysłem, a kończą się dziełem sztuki. Zawsze analizuję każde zagadnienie czy problem, żeby znaleźć proste, często oryginalne rozwiązanie nawet trudnego zadania. Jestem nastawiony na rozwiązanie celu klienta poprzez dostarczenie najlepszej jakości produktu. Wiele osób oraz firm już zaufało mojemu doświadczeniu. <br />Moją pasją jest nie tyle samo programowanie, ile <span>tworzenie czegoś nowego, czegoś, co jeszcze nikt nie robił.</span> </p>
              <p>Krótko mówiąc - jestem w stanie stworzyć wszystko co jest możliwe do zrobienia w zakresie tworzenia rozwiązań internetowych, od stron wewnątrz-firmowych z mnóstwem logiki, do tych prostych informacyjnych witryn jednostronicowych. Zapraszam do kontaktu :)</p>
              <div className="adMydata">
                <h3>Dane do kontaktu: </h3>
                <p> E-mail: <a href={['mailto:', AboutMeData.MyEmail].join('')}>{AboutMeData.MyEmail}</a></p>
                <p> Tel.: <a href={['tel:', AboutMeData.Phone].join('')}>{AboutMeData.Phone}</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
