import React from 'react';
import styles from './SocialMedia.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faVk } from '@fortawesome/free-brands-svg-icons'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'

export default class About extends React.Component<{ className: string }, any> {
  socialMedia = (link: string, color: string, areaLabel:string, icon: any, key: number) => {
    return (
      <a href={link} aria-label={areaLabel} rel="noopener noreferrer" key={key}>
        <FontAwesomeIcon icon={icon} color={color} size="2x" />
      </a>
    )
  }

  render() {
    const { className } = this.props;
    let icons = {
      values: [{
        link: 'https://www.facebook.com/maxcreate14/',
        icon: faFacebook,
        color: '#00815F',
        areaLabel: "Facebook",
        key: 1
      }, {
        link: 'https://vk.com/crossmax15',
        icon: faVk,
        color: '#00815F',
        areaLabel: "VK",
        key: 2
      }, {
        link: 'https://www.instagram.com/crossmax14/',
        icon: faInstagram,
        color: '#00815F',
        areaLabel: "Instagram",
        key: 3
      }, {
        link: 'https://github.com/Crossmax14/',
        icon: faCodeBranch,
        color: '#00815F',
        areaLabel: "Github",
        key: 4
      }]
    }

    return (
      <div className={[styles.socialMedia, className ? className : ''].join(' ')}>
        {icons.values.map(item => this.socialMedia(item.link, item.color, item.areaLabel ,item.icon, item.key))}
      </div>
    );
  }
}
