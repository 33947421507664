import { stylesTheme } from "../constants/Interfaces";
import themeConsts from '../globalStyles/themeConsts.module.css';

const themeSwitch = (themeMode: stylesTheme) => {
    switch (themeMode) {
        case stylesTheme.whiteTheme:
            return themeConsts.whiteTheme;
        case stylesTheme.silverTheme:
            return themeConsts.silverTheme;
        case stylesTheme.darkTheme:
            return themeConsts.darkTheme;
    }
}

export default themeSwitch;